import type { RealtimeChannel } from "@supabase/supabase-js";
import { useCompanyStore } from "@/store/company";
import { useEmployerStore } from "@/store/employer";
import { useTeamStore } from "@/store/team";
import { useChatStore } from "@/store/chat";
import { useJobStore } from "@/store/job";
import { useApplicationStore } from "@/store/application";
import { useNotificationStore } from "@/store/notification";
import { useActivityLogStore } from "@/store/activity_log";
import { usePaymentStore } from "@/store/payment";
import { Credit } from "@/dto/company/credit";
import { Job } from "@/dto/job/job";
import { Message } from "@/dto/chat/message";
import { BROADCAST_EVENTS } from "@/constants/broadcast-events";
import { Chat } from "@/dto/chat/chat";
import { Application } from "@/dto/application/application";
import { EmployerNotification } from "@/dto/notification/notification";
import type { Employer } from "@/dto/employer/employer";
import type { ActivityLog } from "@/dto/activity_log/activity_log";
import type { Payment } from "@/dto/payment/payment";

export const subscribeToListeners = (companyId: number, employerId: string) => {
  const companyStore = useCompanyStore();
  const employerStore = useEmployerStore();
  const teamStore = useTeamStore();
  const chatStore = useChatStore();
  const jobStore = useJobStore();
  const paymentStore = usePaymentStore();
  const notificationStore = useNotificationStore();
  const applicationStore = useApplicationStore();
  const activityLogsStore = useActivityLogStore();
  const { company } = storeToRefs(companyStore);
  const client = useSupabaseClient();
  const companyChannel = client.channel(companyId.toString());

  let notificationRealtimeChannel: RealtimeChannel;
  let chatMessagesRealtimeChannel: RealtimeChannel;
  let applicationsRealtimeChannel: RealtimeChannel;
  let activityLogsRealtimeChannel: RealtimeChannel;

  notificationRealtimeChannel = client
    .channel("public:notifications")
    .on(
      "postgres_changes",
      { event: "*", schema: "public", table: "notifications", filter: `recipient_id=eq.${employerId}` },
      async (payload) => {
        if (payload.eventType === "DELETE") return;
        notificationStore.addNotificationToStore(payload.new as EmployerNotification);
      }
    );

  chatMessagesRealtimeChannel = client
    .channel("public:chat_messages")
    .on(
      "postgres_changes",
      { event: "*", schema: "public", table: "chat_messages", filter: `company_id=eq.${companyId}` },
      async (payload) => {
        if (payload.eventType === "INSERT" && payload.new.sender_id === employerId) return;
        chatStore.addMessageToStore(payload.new as Message);
      }
    );

  applicationsRealtimeChannel = client
    .channel("public:applications")
    .on(
      "postgres_changes",
      { event: "DELETE", schema: "public", table: "applications", filter: `company_id=eq.${companyId}` },
      async (payload) => {
        const { job_id, profile_id } = payload.old;
        const application = applicationStore.getApplicationByPrimaryKeys(job_id, profile_id);

        if (!application) return;

        applicationStore.removeApplicationFromStore(job_id, profile_id);
        chatStore.removeChatFromStore({ applicationId: application.id });
      }
    );

  activityLogsRealtimeChannel = client
    .channel("public:activity_log")
    .on(
      "postgres_changes",
      { event: "INSERT", schema: "public", table: "activity_log", filter: `company_id=eq.${companyId}` },
      async (payload) => {
        activityLogsStore.addLogToStore(payload.new as ActivityLog);
      }
    );

  const eventHandlers: Record<string, (payload: any) => void> = {
    [BROADCAST_EVENTS.member_role_update]: (payload) => {
      employerStore.updateRole(payload.payload as Employer);
      teamStore.updateMemberInStore(payload.payload as Employer);
    },
    [BROADCAST_EVENTS.member_update]: (payload) => {
      teamStore.updateMemberInStore(payload.payload as Employer);
    },
    [BROADCAST_EVENTS.member_invite]: (payload) => {
      teamStore.addMemberToStore(payload.payload as Employer);
    },
    [BROADCAST_EVENTS.member_delete]: (payload) => {
      teamStore.removeMemberFromStore(payload.payload.id as string);
    },
    [BROADCAST_EVENTS.member_invite_cancelled]: (payload) => {
      teamStore.removeMemberFromStore(payload.payload.id as string);
    },
    [BROADCAST_EVENTS.company_update]: (payload) => {
      company.value = payload.payload;
    },
    [BROADCAST_EVENTS.credit_update]: (payload) => {
      companyStore.assignCredits(payload.payload as Credit);
    },
    [BROADCAST_EVENTS.job_update]: (payload) => {
      jobStore.addJobToStore(payload.payload as Job);
    },
    [BROADCAST_EVENTS.job_create]: (payload) => {
      jobStore.addJobToStore(payload.payload as Job);
    },
    [BROADCAST_EVENTS.job_delete]: (payload) => {
      jobStore.removeJobFromStore(payload.payload as Job);
    },
    [BROADCAST_EVENTS.chat_update]: async (payload) => {
      chatStore.addChatToStore(payload.payload as Chat);
    },
    [BROADCAST_EVENTS.chat_delete]: async (payload) => {
      chatStore.removeChatFromStore({ chat: payload.payload as Chat });
    },
    [BROADCAST_EVENTS.application_create]: async (payload) => {
      applicationStore.addApplicationToStore(payload.payload as Application);
    },
    [BROADCAST_EVENTS.application_create_many]: async (payload) => {
      const applications = payload.payload as Application[];
      for (const app of applications) applicationStore.addApplicationToStore(app);
    },
    [BROADCAST_EVENTS.application_update]: async (payload) => {
      applicationStore.addApplicationToStore(payload.payload as Application);
    },
    [BROADCAST_EVENTS.payment_create]: async (payload) => {
      paymentStore.addPaymentToStore(payload.payload as Payment);
    },
    // [BROADCAST_EVENTS.application_delete]: async (payload) => {
    //   applicationStore.removeApplicationFromStore(payload.payload as Application);
    // },
  };

  Object.entries(eventHandlers).forEach(([event, handler]) => {
    companyChannel.on("broadcast", { event }, handler);
  });

  companyChannel.subscribe();
  notificationRealtimeChannel.subscribe();
  chatMessagesRealtimeChannel.subscribe();
  applicationsRealtimeChannel.subscribe();
  activityLogsRealtimeChannel.subscribe();
};
